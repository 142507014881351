import { useState } from 'react';

import {
  ProductDetailsApiDocument,
  ProductDetailsValue,
  ProductPrice,
} from '@hultafors/shared/types';

import { createUrl, spacing } from '@hultafors/solidgear/helpers';
import { useFavorites, useGlobal } from '@hultafors/solidgear/hooks';
import {
  ProductSize,
  SafetyClassValue,
  SolidgearProductDetails,
} from '@hultafors/solidgear/types';

import { Favorite } from '../Favorite/favorite';
import { Image } from '../Image/Image';
import { Paragraph } from '../paragraph/paragraph';
import { ProductInformationTabs } from '../product-information-tabs/product-information-tabs';
import { SafetyClassDivision } from '../SafetyClassDivision/SafetyClassDivision';
import { SizeSelector } from '../size-selector/size-selector';
import { TextLink } from '../text-link/text-link';

import { ProductDetailInformationStyled } from './product-detail-information.style';

interface ProductDetailInformationProps {
  name?: string;
  skuLabel?: string;
  sku?: string;
  price?: ProductPrice;
  priceWithVat?: ProductPrice;
  rrpLabel?: string;
  rrpIncludingVatLabel?: string;
  features?: ProductDetailsValue[];
  sizeGuideLabel?: string;
  chooseSizeLabel?: string;
  addToShoppingListButtonLabel?: string;
  overviewLabel?: string;
  documentsLabel?: string;
  detailsLabel?: string;
  intro?: string;
  detailsInfo?: string;
  documents?: ProductDetailsApiDocument[];
  materials?: ProductDetailsValue[];
  materialsHeader?: string;
  productFeature?: string;
  sizes?: ProductSize[];
  productForStorage?: SolidgearProductDetails;
  materialsLinkText: string;
  materialsLink: string;
  safetyClassLabel?: string;
  safetyClass?: SafetyClassValue;
}

export const ProductDetailInformation: React.FC<
  ProductDetailInformationProps
> = ({
  name,
  skuLabel,
  sku,
  price,
  priceWithVat,
  rrpLabel,
  rrpIncludingVatLabel,
  features,
  chooseSizeLabel,
  overviewLabel,
  documentsLabel,
  detailsLabel,
  intro,
  documents,
  detailsInfo,
  productFeature,
  materials,
  materialsHeader,
  sizes,
  productForStorage,
  materialsLinkText,
  materialsLink,
  safetyClassLabel,
  safetyClass,
}) => {
  const { settings } = useGlobal();
  const { favorites, toggleFavoriteItem, isProductFavorite } = useFavorites();
  const [isSizeSelectorOpen, setIsSizeSelectorOpen] = useState(false);
  const [selectedSize, setSelectedSize] = useState<ProductSize>();

  const toggleSizeSelector = () => {
    setIsSizeSelectorOpen(!isSizeSelectorOpen);
  };

  const featuresFilter = (feature: ProductDetailsValue) => {
    if (
      !feature.text ||
      (typeof feature.text === 'string' && feature.text.trim().length === 0) ||
      feature.image === null ||
      feature.image === undefined
    ) {
      return null;
    }

    return feature;
  };

  const isSVG = (url: string) => {
    return url.split('.').pop() === 'svg';
  };

  const toggleSelectSize = (size: ProductSize) => {
    setSelectedSize(size);
    setIsSizeSelectorOpen(false);
  };

  return (
    <ProductDetailInformationStyled>
      <div
        style={{ marginBottom: safetyClass ? 0 : spacing.regular }}
        className="InfoFirstBlock"
      >
        {productFeature && <span className="NewLabel">{productFeature}</span>}
        <div className="NameAndFavoriteContainer">
          {name && <h1>{name}</h1>}
          {productForStorage && (
            <Favorite
              toggleFavorite={toggleFavoriteItem}
              product={productForStorage}
              isFavorite={isProductFavorite(sku || '')}
              inFavorites={false}
              bigStar
            />
          )}
        </div>
        {sku && (
          <Paragraph small className="ArticleNumber">
            {skuLabel} {sku}
          </Paragraph>
        )}
        {price?.value ? (
          <div className="PriceWrapper">
            <Paragraph>{`${price.currency} ${price.value}`}</Paragraph>
            <Paragraph xSmall className="RRPLabel">
              {rrpLabel}
            </Paragraph>
          </div>
        ) : priceWithVat?.value ? (
          <div className="PriceWrapper">
            <Paragraph>{`${priceWithVat.currency} ${priceWithVat.value}`}</Paragraph>
            <Paragraph xSmall className="RRPLabel">
              {rrpIncludingVatLabel}
            </Paragraph>
          </div>
        ) : null}
      </div>
      {safetyClass && (
        <SafetyClassDivision
          safetyClassLabel={safetyClassLabel || ''}
          safetyClass={safetyClass}
        />
      )}
      {!!features?.length && (
        <div className="FeatureList">
          <ul>
            {features.filter(featuresFilter).map((feature, i) => (
              <li key={`featureKey${i}`}>{feature.text}</li>
            ))}
          </ul>
        </div>
      )}
      <ProductInformationTabs
        overviewLabel={overviewLabel}
        documentsLabel={documentsLabel}
        detailsLabel={detailsLabel}
        intro={intro}
        detailsInfo={detailsInfo}
        documents={documents}
      />

      {materials && (
        <div className="Materials">
          <h2 className="MaterialsTitle">{materialsHeader}</h2>
          <div className="LogoWrapper">
            {materials.map((item, index) => {
              return (
                <div className="MaterialItem" key={`Material-${index}`}>
                  {item.image && item.image.url && isSVG(item.image.url) ? (
                    <Image
                      caption={item.text}
                      alt={item.text || 'image'}
                      src={item.image.url}
                    />
                  ) : (
                    <Image
                      caption={item.text}
                      alt={item.text || 'image'}
                      src={item.image?.url || ''}
                    />
                  )}
                  {/* <Paragraph>{item.text}</Paragraph> */}
                </div>
              );
            })}
          </div>
          <TextLink
            className="MaterialsLink"
            iconUrl="/assets/gfx/arrow-right.svg"
            href={createUrl(materialsLink, settings)}
            alt={materialsLinkText}
          >
            {materialsLinkText}
          </TextLink>
        </div>
      )}

      <SizeSelector
        toggle={toggleSizeSelector}
        isOpen={isSizeSelectorOpen}
        headerText={chooseSizeLabel}
        toggleSelectSize={toggleSelectSize}
        selectedSize={selectedSize}
        sizes={sizes}
      />
    </ProductDetailInformationStyled>
  );
};
