import styled, { css } from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';

interface PageStyledProps {
  $isLanguageSelectorVisible?: boolean;
  $attachFooterToBottom?: boolean;
}

export const PageStyled = styled.div<PageStyledProps>`
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-x: hidden;

  ${({ $attachFooterToBottom }) =>
    $attachFooterToBottom &&
    css`
      display: flex;
      flex-direction: column;
    `}

  a {
    color: ${colors.black};
  }

  @media (width >= 1440px) {
    ${({ $attachFooterToBottom }) =>
      $attachFooterToBottom &&
      css`
        display: flex;
        flex-direction: column;
      `}
  }
`;
